
.hotspot-container {
  min-height: 200px;
  background-color: #fff;
  padding: 24px;

  .head-box {
    [class$="_label"] {
      /* 所有类名以 '-label' 结尾的样式 */
      font-weight: 400;
      font-size: 14px;
      color: #252632;
      line-height: 16px;
      font-style: normal;
    }
    .time-filter {
      float: left;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      z-index: 10;

      // ::v-deep .el-input__inner {
      //   background-color: #f2f2f4;
      //   color: #252632;
      //   border: none;
      //   padding: 6px 10px 6px 16px;
      // }

      // ::v-deep .el-select .el-input .el-select__caret {
      //   color: #252632;
      // }

      ::v-deep .el-date-editor.el-input,
      ::v-deep .el-date-editor.el-input__inner {
        width: 135px;
      }

      ::v-deep .el-input__inner {
        background-color: #f2f2f4;
        color: #252632;
        border: none;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
      }

      ::v-deep .el-input__icon {
        line-height: 28px;
      }
    }

    .exact-time-filter {
      &_label {
        float: left;
        margin-left: 26px;
        height: 28px;
        line-height: 28px;
      }

      &_check {
        position: relative;

        .hour-button {
          position: relative;
          display: inline-block;
          padding: 6px 24px;
          background-color: #f2f2f4;
          border-radius: 4px;
          margin-left: 20px;
          margin-bottom: 16px;
          font-size: 14px;
          color: rgba(37, 38, 50, 0.6);
          line-height: 16px;
          cursor: pointer;

          &.checked {
            color: #594fee;
            background-color: #ecebff;
          }

          &:hover {
            color: #594fee;
          }

          &:not(.hour-button:nth-child(1))::before {
            content: "";
            position: absolute;
            border-top: 2px dotted rgba(37, 38, 50, 0.4);
            width: 20px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .content-box {
    min-height: 300px;
    // max-height: calc(100vh - 230px);
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #eaebed;
    padding-top: 24px;
    ::v-deep .el-scrollbar .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .hotspot-type {
      ::v-deep .el-radio-button--small .el-radio-button__inner {
        padding: 6px 24px;
      }
      ::v-deep .el-radio-button__inner {
        background-color: #f2f2f4;
        border: none !important;
      }
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        box-shadow: none;
        background-color: #594fee;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #594fee !important;
      }

      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
        color: #ffffff !important;
      }
    }

    .hotspot-main {
      margin-top: 24px;
      min-height: 50px;
      // background-color: #f2f2f4;

      /* collspace header */
      &_header {
        display: flex;
        align-items: center;
        height: 60px;
        gap: 12px;
        overflow: hidden;

        .header-cover {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          i {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 4px 0px 8px 0px;
            top: 0;
            left: 0;
            border-right: 1px solid #f7f9fb;
            border-bottom: 1px solid #f7f9fb;
            font-style: normal;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-size: 10px;
            background-color: #929399;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            // border: 1px solid #000;
          }
        }

        .header-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          line-height: 16px;
          overflow: hidden;

          &_sentence {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出的部分 */
            text-overflow: ellipsis; /* 用省略号表示被截断的文本 */
          }

          &_hot {
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            display: flex;
            align-items: center;
            gap: 25px;

            &-detail {
              display: flex;
              align-items: center;
              gap: 3px;

              .hot-icon {
                width: 18px;
                height: 18px;
                background-color: #e8eaef;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg.icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }

      /* special header i and icon */
      .hotspot-main-item:nth-child(1) {
        .hotspot-main_header .header-cover-rank {
          background-color: #ff2c55;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f9dde0 !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ff2c55;
        }
      }

      .hotspot-main-item:nth-child(2) {
        .hotspot-main_header .header-cover-rank {
          background-color: #ff851d;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f9dfda !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ff7352;
        }
      }

      .hotspot-main-item:nth-child(3) {
        .hotspot-main_header .header-cover-rank {
          background-color: #face15;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon {
          background-color: #f8e7d1 !important;
        }
        .header-info_hot-detail:nth-child(1) .hot-icon svg path {
          fill: #ffa132;
        }
      }

      /* collspace content */
      &_content {
        display: flex;
        justify-content: space-between;

        .content-video {
          // width: 65%;
          // height: 50px;
          // background-color: #000;
          padding: 16px 0;
          border-top: 1px solid #eaebed;

          &_label {
            font-family: PingFang SC, PingFang SC;
            font-size: 12px;
            color: #111111;
            line-height: 14px;
            margin-bottom: 16px;
          }

          &_list {
            display: flex;
            gap: 18px;

            .video-img {
              position: relative;
              width: 68px;
              height: 90px;
              border-radius: 4px;
              overflow: hidden;

              box-sizing: border-box;

              .video-play {
                position: absolute;
                width: 20px;
                height: 24px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                svg.icon {
                  width: 23px;
                  height: 28px;
                  cursor: pointer;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .content-filp {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          padding-top: 16px;
          flex-wrap: wrap;

          ::v-deep(.el-button) {
            background-color: #e5e7ec;
            color: #7b828b;
          }

          ::v-deep(.el-button.is-disabled) {
            background-color: #e5e7ec;
            color: #cbced4;
          }
        }
      }

      /* elementui style */
      ::v-deep .el-collapse-item {
        margin-bottom: 16px;
      }

      ::v-deep .el-collapse-item__arrow {
        display: none; // 右侧icon不需要
      }

      ::v-deep .el-collapse-item__header {
        background-color: #f7f9fb;
        padding: 16px 24px;
        border: none !important;
      }

      ::v-deep .el-collapse {
        border: none !important;
      }

      ::v-deep .el-collapse-item__content {
        background-color: #f7f9fb;
        // background-color: #f2f2f4;
        padding: 0 24px;
      }

      ::v-deep .el-button {
        background-color: #e5e7ec;
        border: none;
        color: #7b828b;
      }

      ::v-deep .el-button:focus,
      .el-button:hover {
        color: #594fee;
      }
    }

    /* load style */
    .hotspot-load {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
